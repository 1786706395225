export const SARAIVA_CLIENT = getEnv(
    "SARAIVA_CLIENT",
    "TST"
);
export const SARAIVA_LIBRARY = getEnv(
    "SARAIVA_LIBRARY",
    "tst27012021"
);
export const SARAIVA_SECRET = getEnv(
    "SARAIVA_SECRET",
    "oQaiajr630yKRiCNnD2XomBMUHwAy85FNKbGSRDL92sKW8Z0F0QLl9UUI1qcIgkx"
);
export const SARAIVA_END_POINT = getEnv(
    "SARAIVA_END_POINT",
    "http://stg.biblioteca.saraivaeducacao.com.br/api/access_bds"
);
export const SARAIVA_EXPIRATION_HOURS = getEnv(
    "SARAIVA_EXPIRATION_HOURS",
    "2"
);
export const KEYCLOAK_URL = getEnv(
    "KEYCLOAK_URL", 
    "http://localhost:9090/auth"
);
export const TIPO_AMBIENTE = getEnv(
    "TIPO_AMBIENTE", 
    "homologacao"
);

function getEnv(envName, defaultValue) {
    if (!window.env) {
      window.env = {};
    }
  
    if (window.env[envName] == null) {
      window.env[envName] = defaultValue;
    }
  
    return window.env[envName];
  }