import Token from "./Token";
import keycloak from "./keycloak";
import { convertDateTime, addHours } from "./DateTimeUtil";
import {
  SARAIVA_CLIENT,
  SARAIVA_LIBRARY,
  SARAIVA_SECRET,
  SARAIVA_END_POINT,
  SARAIVA_EXPIRATION_HOURS,
} from "./env.js";

export const link = () => {
  const { preferred_username: username = "0" } =
    (keycloak && keycloak.idTokenParsed) || {};
  let agora = new Date();
  let iat = agora.getTime();
  let expired_at = convertDateTime(addHours(agora, SARAIVA_EXPIRATION_HOURS));
  let token = new Token(
    SARAIVA_CLIENT,
    username,
    SARAIVA_LIBRARY,
    expired_at,
    iat,
    SARAIVA_SECRET
  );
  return (
    SARAIVA_END_POINT +
    "?client_name=" +
    SARAIVA_CLIENT +
    "&token=" +
    token.encoded
  );
};
