import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import log from "loglevel";
import App from "./App";
import keycloak from "./keycloak";

keycloak
  .init({
    onLoad: "login-required",
  })
  .then((authenticated) => {
    if (authenticated) {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  })
  .catch((err) => {
    log.error("Falha ao iniciar o keycloak: ", err);
  });
