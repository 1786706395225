import jsonWebTokenService from "jsonwebtoken";

export default class Token {
  constructor(client, external_id, library_code, expiration_at, iat, secret) {
    /** @type {string} */
    this.client = client;

    /** @type {string} */
    this.external_id = external_id;

    /** @type {string} */
    this.library_code = library_code;

    /** @type {string} */
    this.expiration_at = expiration_at;

    /** @type {string} */
    this.iat = iat;

    /** @type {string} */
    this.secret = secret;
  }

  get encoded() {
    let obj = {
      root_url: `https://www.tst.jus.br`,
      external_id: `${this.external_id}`,
      library_code: `${this.library_code}`,
      expiration_at: `${this.expiration_at}`,
      iat: `${this.iat}`,
    };
    console.log(obj);

    let json = JSON.stringify(obj);
    let codificado = jsonWebTokenService.sign(json, this.secret, {
      algorithm: "HS256",
      header: { alg: "HS256", typ: "JWT" },
    });
    return codificado;
  }
}
