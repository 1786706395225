import './App.css';
import {link } from "./Saraiva";
import { SARAIVA_EXPIRATION_HOURS } from './env.js';
import { useAppInfo } from "./InfoApi";

function App() {
  const { appInfo } = useAppInfo();

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Use o link abaixo para acessar a Biblioteca Digital Saraiva.
        </p>
        <a
          className="App-link"
          href={link()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Biblioteca Digital Saraiva
        </a>
        <p className="Message">
        Este link tem validade de {SARAIVA_EXPIRATION_HOURS} horas. Passado esse tempo, 
        recarregue esta página para gerar um novo link.
        </p>
        <p className="Message">
        v.{appInfo ? appInfo.versao : "??" } - b.{appInfo ? appInfo.build : "??" } - {appInfo ? appInfo.data : "??" }
        </p>
      </header>
    </div>
  );
}

export default App;
