import Keycloak from "keycloak-js";
import { KEYCLOAK_URL } from "./env";

const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: "tst-users",
  clientId: "bdsaraiva-app"
});

export function updateToken(minValidity = 5) {
  return new Promise((resolve, reject) => {
    if (keycloak.isTokenExpired(minValidity)) {
      keycloak.updateToken(minValidity).success(resolve).error(reject);
    } else {
      resolve();
    }
  });
}

export function isUserInRole(role) {
  return (
    keycloak.tokenParsed &&
    keycloak.tokenParsed["realm_access"]["roles"].indexOf(role) > -1
  );
}

export default keycloak;
